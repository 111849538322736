/* Root container styles */
#root {
  max-width: 1920px;
  margin: auto;
}

/* Header styles */
.header {
  font-size: 24px;
  margin-bottom: 20px;
}

/* QR code container styles */
.qr-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
}

/* Color picker container styles */
.color-pickers {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Button wrapper styles */
.button-wrap {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Responsive design for screens smaller than 600px */
@media (max-width: 600px) {
  .qr-code-container,
  .color-pickers,
  .button-wrap {
    flex-direction: column;
    align-items: center;
  }

  .App-header {
    justify-content: flex-start;
    padding-top: 10px;
  }

  .button-wrap {
    flex-direction: column;
  }
}
